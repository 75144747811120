<template>
  <div
    class="input-group color-picker"
    style="position: relative"
    ref="colorpicker"
  >
    <vs-row>
      <vs-col vs-w="11">
        <ValidationProvider
          name="اللون"
          rules="required"
          v-slot="{ errors }"
        >
          <vs-input
            type="text"
            label="اللون"
            v-model="colorValue"
            @focus="showPicker()"
            @input="updateFromInput"
            style="width: 100%"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </vs-col>
      <vs-col vs-w="1">
        <vs-card
          style="background-color: #EFEFEF; height: 50px; width: 40px; margin-top: 20px; box-shadow: none; border-radius:3px">
          <span
            class="current-color mt-2"
            :style="'background-color: ' + colorValue"
            @click="togglePicker()"
          />
          <chrome-picker
            :value="colors"
            @input="updateFromPicker"
            v-if="displayPicker"
          />
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

import {Chrome} from 'vue-color';

export default {
  name: "ColorPicker",
  components: {
    'chrome-picker': Chrome,
  },
  props: {
    color: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      colors: {
        hex: '#000000',
      },
      colorValue: '',
      displayPicker: false,
    };
  },
  mounted() {

    this.setColor(this.color || '#000000');
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color
        };
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    }
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
        //document.body.style.background = val;
      }
    }
  },
};
</script>

<style scoped>
.vc-chrome {
  position: absolute;
  top: 68px;
  right: 0px;
  left: 20%;
  z-index: 9;
}

.color-picker-container {
  width: 50px !important;
  height: 20px !important;
  background-color: #F2F2F2;
}

.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
</style>
